import React from 'react'
import linkHasExpired from '../../static/media/link-has-expired.png'
import { CONTACT_MESSAGE, LINK_HAS_EXPIRED } from 'utils/messages'
import tw from 'twin.macro'
import styled from 'styled-components/macro'

const NotAuthorizedWrapper = styled.div`
	${tw`flex justify-center items-center h-full w-full flex-col`}
`

const ImgContainer = styled.img`
	${tw`h-[410px] w-[403px]`}
`

const ContactContainer = styled.div`
	${tw`pt-4`}
`

const NotAuthorized = () => {
	return (
		<NotAuthorizedWrapper>
			<ImgContainer
				alt={LINK_HAS_EXPIRED}
				src={linkHasExpired}
			/>
			<ContactContainer>
				{ CONTACT_MESSAGE }
			</ContactContainer>
		</NotAuthorizedWrapper>
	)
}

export default NotAuthorized
