import './ErrorMessage.css';
import React from 'react';
import PropTypes from 'prop-types';

export const ErrorMessage = ({ message }) => {
    return (
        <div className='error-message-container'>
            <div className='error-message-icon' />
            <span className='error-message-text'>{message}</span>
        </div>
    )
}

ErrorMessage.propTypes = {
    message: PropTypes.string
}