import React from 'react'
import './LoginPage.css'
import {
    LOGIN_LOGO_ALT,
    LOGIN_PRIVACY,
    LOGIN_TERMS,
    LOGIN_FOOTER_COPYRIGHT
} from '/utils/messages';
import loginLogo from '../../../../static/media/login-logo.png';

const LoginPage = ({ Form, location }) => {
    return (
        <div className='login-page-container'>
            <img
                alt={LOGIN_LOGO_ALT}
                src={loginLogo}
                className='login-page-logo'
            />
            <div className='login-page-right-panel'>
                <div className='login-page-form-container'>
                    <Form location={location} />
                </div>
                <div className='login-page-footer-container'>
                    <div className='login-page-footer-copyright'>{LOGIN_FOOTER_COPYRIGHT}</div>
                    <div className='login-page-footer-links'>
                        <div>{LOGIN_PRIVACY}</div>
                        <div>{LOGIN_TERMS}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LoginPage;
