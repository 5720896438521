import React from 'react'
import LoginPage from "components/container/LoginPage/LoginPage";
import {ActivationForm} from "components/forms/ActivationForm/ActivationForm";

const ActivateAccount = ({ location }) => {
    return (
        <LoginPage Form={ActivationForm} location={location?.pathname} />
    )
}
export default ActivateAccount;
