import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'
import 'components/forms/ActivationForm/ActivationForm.css'
import {
	ACTIVATION_DATE_OF_BIRTH,
	ACTIVATION_HEADER,
	ACTIVATION_TEXT,
	INVALID_BIRTH_DATE,
} from 'utils/messages'
import Loader from 'components/global/Loader/Loader'
import {
	ButtonTypes,
	ButtonSizes,
	ButtonLabels,
	FetchMethods,
} from 'utils/constants'
import { Button } from 'components/buttons/Button/Button'
import { FormDatePicker2 } from 'components/formFields/FormDatePicker2/FormDatePicker2'
import {
	MAGIC_LINK_LOGIN,
	CHECK_THE_CURRENT_USER_BIRTH_DATE,
} from 'common/endpoints'
import fetchApi from 'common/fetchApi'
import { useDispatch } from 'react-redux'
import { prepareDate } from 'utils/helpers/format.helper'
import { ErrorMessage } from 'components/global/ErrorMessage/ErrorMessage'
import NotAuthorized from 'pages/notAuthorized'

export const ActivationForm = ({ location }) => {
	const { handleSubmit, control } = useForm()
	const [isLoading, setIsLoading] = useState()
	const [date, setDate] = useState()
	const dispatch = useDispatch()
	const [showError, setShowError] = useState(false)
	const [authorized, setAuthorized] = useState(false)

	useEffect(() => {
		const loginByMagicLink = async () => {
			setIsLoading(true)
			try {
				const urlArray = location.split('/')
				const response = await fetchApi(MAGIC_LINK_LOGIN, {
					method: FetchMethods.Post,
					data: {
						sso: urlArray[urlArray.length - 1],
					},
				})
				dispatch.auth.setLoggedIn({ ...response.data })
				setAuthorized(true)
				setIsLoading(false)
			} catch (error) {
				console.log(error)
				setAuthorized(false)
				setIsLoading(false)
			}
		}

		loginByMagicLink()
	}, [])

	const onSubmit = async (data) => {
		const birthDate = prepareDate(data?.date_of_birth)

		setIsLoading(true)
		try {
			const response = await fetchApi(CHECK_THE_CURRENT_USER_BIRTH_DATE, {
				method: FetchMethods.Get,
				params: { date_of_birth: birthDate },
			})

			const { result } = response.data
			setIsLoading(false)

			if (!result) {
				setShowError(true)
				return
			}
			dispatch.auth.setBirthDate({ birthDate })
			await navigate('/app/setupPassword/')
		} catch (error) {
			setShowError(true)
			setIsLoading(false)
		}
	}

	if (isLoading) {
		return (
			<div className="activation-loader-container">
				<Loader />
			</div>
		)
	}

	if (!authorized) {
		return <NotAuthorized />
	}
	return (
		<form className="activation-form">
			<div className="activation-header">{ACTIVATION_HEADER}</div>
			{showError && <ErrorMessage message={INVALID_BIRTH_DATE} />}
			<div className="activation-text">{ACTIVATION_TEXT}</div>
			<div className="activation-date">
				<FormDatePicker2
					label={ACTIVATION_DATE_OF_BIRTH}
					isRequired
					control={control}
					onChange={setDate}
					fieldName="date_of_birth"
					dataTestId="activation-date-of-birth"
				/>
			</div>
			<div className="activation-next-button">
				<Button
					handleOnClick={handleSubmit(onSubmit)}
					type={ButtonTypes.Primary}
					size={ButtonSizes.Large}
					label={ButtonLabels.Next}
					dataTestId="activation-next-button"
				/>
			</div>
		</form>
	)
}
